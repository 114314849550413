import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Header from "../layout/Header";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import { useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import AuthApi from "../services/authApi";
import AuthApi1 from "../services/authApi";
import authApi2 from "../services/authApi";
import Loader from "react-js-loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import info from "../../assets/images/info.png";
// import makeStyles from "@emotion/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Divider,
} from "@mui/material";
import "./MoveText.css";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faDisplay,
  faIdCardClip,
  faReceipt,
  faCalendarDay,
  faCircleInfo,
  faLaptopHouse,
  faHeartPulse,
} from "@fortawesome/free-solid-svg-icons";
import { toInteger } from "lodash";
import axios from "axios";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
 // const { search } = useLocation();
  var [isLoading, setLoading] = useState(true);
  //const { custV } = queryString.parse(search);
  //const { custNumber } = queryString.parse(search);
  const search = window.location.search || ''; // Ensure `search` is a string

  // Safely parse the query string
  const { custV = '', custNumber = '' } = queryString.parse(search);
  

  var [isButtonLoading, setButtonLoading] = useState(false);
  const { base64encode, base64decode } = require("nodejs-base64");

  // let encoded = base64encode(custNumber);
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  useEffect(() => {
    //razorflag();

    localStorage.clear();
    getDetailsCust().then(() => {
      fetchAdBanner();
    });
  }, []);

  const getpackdetails = async (
    Merchant_Salt,
    AGREEMENT_NO,
    owner_id,
    cust_num,
    MSO_ID
  ) => {
    let userData = {
      lcousername: Merchant_Salt,
      nxt_id: AGREEMENT_NO,
      owner_id: owner_id,
      cust_num: cust_num,
      MSO: MSO_ID,
    }; //
    setLoading(false);
    isLoading = false;
  };

  // const getDetails = async () => {
  //   isLoading = true;

  //   let decoded = base64decode(custNumber);
  //   let userData = {
  //     CUST_NUM: decoded,
  //     FLAG: "B",
  //   };
  //   await AuthApi1.getdetail(userData)
  //     .then((response) => {
  //       let data = response.data.customerInfo;
  //       let newDateFormat = moment(data.PRE_END_DATE, 'YYYY-MM-DD').format('DD-MMMM-YYYY');
  //       localStorage.setItem("custNum", data.cust_num);
  //       localStorage.setItem("custID", data.CUSTOMER_ID);
  //       localStorage.setItem("phone", data.Phone);
  //       localStorage.setItem("custName", data.name);
  //       localStorage.setItem("email", data.EMAIL);
  //       localStorage.setItem("stbNumber", data.stb);
  //       localStorage.setItem("op_email", data.OP_Email_Id);
  //       localStorage.setItem("op_phone", data.Contact_Number);
  //       localStorage.setItem("dueAmount", data.bill_total);
  //       localStorage.setItem("bill_total", data.total_pay);
  //       localStorage.setItem("advn_dep_amnt",data.cust_advn_dep_amnt);
  //       localStorage.setItem("packTotal",data.L_PACK_TOTAL_AMOUNT);
  //       localStorage.setItem("cust_due",data.cust_due_amnt);
  //       localStorage.setItem("operatorId", data.operator_id);
  //       localStorage.setItem("operatorName", data.operator_comp_name);
  //       localStorage.setItem("Merchant_Salt", data.Merchant_Salt);
  //       localStorage.setItem("AGREEMENT_NO", data.AGREEMENT_NO);
  //       localStorage.setItem("PRE_END_DATE", newDateFormat);
  //       localStorage.setItem("rem_days", data.rem_days);
  // 	    localStorage.setItem("STB_ID", data.STB_ID);
  //       localStorage.setItem("MSO_ID",data.MSO_ID);
  //       localStorage.setItem("AddChannelFlag",data.ADD_CHANNEL);
  //       getpackdetails(
  //         data.Merchant_Salt,
  //         data.AGREEMENT_NO,
  //         data.operator_id,
  //         data.cust_num,
  //         data.MSO_ID
  //       );
  //       //getAddChannelFlag(data.operator_id);
  //     })
  //     .catch((e) => {
  //       alert("error in getting details");
  //       console.log(e);
  //       isLoading = false;
  //     });

  //     let userData1 = {
  //       CUST_NUM: decoded,
  //       FLAG: "V",
  //     };
  //     await AuthApi1.getdetail(userData1)
  //     .then((response) => {
  //       // console.log(response);
  //       let data = response.data.customerInfo;
  //       localStorage.setItem("stb_flag", data.stb_enabled);
  //       localStorage.setItem("payment_flag", data.payment_enabled);
  //     })
  //     .catch((e) => {
  //       alert("error in getting details");
  //       console.log(e);
  //       isLoading = false;
  //     });

  //   //   const stb_flag=localStorage.getItem("stb_flag");
  //   //   const payment_flag=localStorage.getItem("payment_flag");

  //   //   if (stb_flag === 'Y' && payment_flag === 'Y') {
  //   //   if (new1Date >= exDate) {
  //   //     setTotalPayFlagShow(true);
  //   //     setPaymentFlagShow(true);
  //   //     setStbFlagShow(false);
  //   //   } else {
  //   //     setStbFlagShow(true);
  //   //     setTotalPayFlagShow(false);
  //   //     setPaymentFlagShow(false);
  //   //   }
  //   // }else{
  //   //   if (stb_flag === 'Y' && payment_flag === 'N') {
  //   //     setStbFlagShow(true);
  //   //     setPaymentFlagShow(false);
  //   //     setTotalPayFlagShow(false);
  //   //   } else if (stb_flag === 'N' && payment_flag === 'Y') {
  //   //     setPaymentFlagShow(true);
  //   //     setTotalPayFlagShow(false);
  //   //     setStbFlagShow(false);
  //   //   } else {
  //   //     setStbFlagShow(false);
  //   //     setPaymentFlagShow(false);
  //   //     setTotalPayFlagShow(false);
  //   //   }
  //   // }
//custNumber
  // };

  const getDetailsCust = async () => {
    isLoading = true;

    let decoded = base64decode(custNumber);
    let decodeVc=base64decode(custV);
    console.log(decodeVc);
    let [vcNumber, operatorId] = decodeVc.split('-');
    let flag=decoded?"B":"A"
     if(flag==="B"){
      console.log('B blc')
      let userData = {
        CUST_NUM: decoded,
        VC_NUMBER1:decodeVc,
         flag: "A",
      };
      await AuthApi1.getdetailCust(userData)
        .then((response) => {
          let data = response.data.QRINFO;
          let newTotalAmount =
            parseFloat(data.PACK_AMOUNT) +
            parseFloat(data.DUE) +
            parseFloat(data.L_ADV_AMNT);
          let totalPay = newTotalAmount.toFixed(2);
          let totalBill = parseFloat(data.AMOUNT).toFixed(2);
          let newDateFormat = moment(data.END_DATE, "YYYY-MM-DD").format(
            "DD-MMMM-YYYY"
          );
          localStorage.setItem("custNum", data.L_CUST_NUM);
          localStorage.setItem("custID", data.L_CUST_ID);
          localStorage.setItem("phone", data.CPHONE);
          localStorage.setItem("custName", data.CNAME);
          // localStorage.setItem("email", data.EMAIL);
          localStorage.setItem("stbNumber", data.STB_NUMBER);
          localStorage.setItem("op_email", data.OEMAIL);
          localStorage.setItem("op_phone", data.SUPPORT);
          localStorage.setItem("dueAmount", totalPay);
          localStorage.setItem("bill_total", totalBill);
          localStorage.setItem("advn_dep_amnt", data.L_ADV_AMNT);
          localStorage.setItem("packTotal", data.PACK_AMOUNT);
          localStorage.setItem("cust_due", data.DUE);
          localStorage.setItem("operatorId", data.OPERATOR_ID);
          localStorage.setItem("operatorName", data.NETWORK);
          localStorage.setItem("op_address", data.OP_ADDRESS);
          localStorage.setItem("Merchant_Salt", data.SALT);
          localStorage.setItem("AGREEMENT", data.AGREEMENT);
          localStorage.setItem("PRE_END_DATE", newDateFormat);
          localStorage.setItem("rem_days", data.DAYS);
          localStorage.setItem("STB_ID", data.STB_ID);
          localStorage.setItem("MSO_ID", data.MSO);
          localStorage.setItem("AddChannelFlag", data.L_add_new_channel);
          localStorage.setItem("no_of_box", data.NO_BOX);
          localStorage.setItem("show_type", data.SHOW_TYPE);
          localStorage.setItem("can_pay", data.CAN_PAY);
          localStorage.setItem("message", data.MESSAGE);
          localStorage.setItem("merchant_id", data.MERCHANTID);
          localStorage.setItem("merchant_key", data.MKEY);
          localStorage.setItem("VC_NUMBER", data.VC_NUMBER);
          getpackdetails(
            data.SALT,
            data.AGREEMENT,
            data.OPERATOR_ID,
            data.L_CUST_NUM,
            data.MSO
          );
          //getAddChannelFlag(data.operator_id);
        })
        .catch((e) => {
          alert("error in getting details");
          console.log(e);
          isLoading = false;
        });
    }
    else{
      console.log('A blc')
      let userData = {
        CUST_NUM: decoded,
        VC_NUMBER1:vcNumber,
         flag: "A",
         OPERATOR_ID:operatorId
      };
      await AuthApi1.getdetailCustByVc(userData)
        .then((response) => {
          let data = response.data.QRINFO;
          let newTotalAmount =
            parseFloat(data.PACK_AMOUNT) +
            parseFloat(data.DUE) +
            parseFloat(data.L_ADV_AMNT);
          let totalPay = newTotalAmount.toFixed(2);
          let totalBill = parseFloat(data.AMOUNT).toFixed(2);
          let newDateFormat = moment(data.END_DATE, "YYYY-MM-DD").format(
            "DD-MMMM-YYYY"
          );
          localStorage.setItem("custNum", data.L_CUST_NUM);
          localStorage.setItem("custID", data.L_CUST_ID);
          localStorage.setItem("phone", data.CPHONE);
          localStorage.setItem("custName", data.CNAME);
          // localStorage.setItem("email", data.EMAIL);
          localStorage.setItem("stbNumber", data.STB_NUMBER);
          localStorage.setItem("op_email", data.OEMAIL);
          localStorage.setItem("op_phone", data.SUPPORT);
          localStorage.setItem("dueAmount", totalPay);
          localStorage.setItem("bill_total", totalBill);
          localStorage.setItem("advn_dep_amnt", data.L_ADV_AMNT);
          localStorage.setItem("packTotal", data.PACK_AMOUNT);
          localStorage.setItem("cust_due", data.DUE);
          localStorage.setItem("operatorId", data.OPERATOR_ID);
          localStorage.setItem("operatorName", data.NETWORK);
          localStorage.setItem("op_address", data.OP_ADDRESS);
          localStorage.setItem("Merchant_Salt", data.SALT);
          localStorage.setItem("AGREEMENT", data.AGREEMENT);
          localStorage.setItem("PRE_END_DATE", newDateFormat);
          localStorage.setItem("rem_days", data.DAYS);
          localStorage.setItem("STB_ID", data.STB_ID);
          localStorage.setItem("MSO_ID", data.MSO);
          localStorage.setItem("AddChannelFlag", data.L_add_new_channel);
          localStorage.setItem("no_of_box", data.NO_BOX);
          localStorage.setItem("show_type", data.SHOW_TYPE);
          localStorage.setItem("can_pay", data.CAN_PAY);
          localStorage.setItem("message", data.MESSAGE);
          localStorage.setItem("merchant_id", data.MERCHANTID);
          localStorage.setItem("merchant_key", data.MKEY);
          localStorage.setItem("VC_NUMBER", data.VC_NUMBER);
          getpackdetails(
            data.SALT,
            data.AGREEMENT,
            data.OPERATOR_ID,
            data.L_CUST_NUM,
            data.MSO
          );
          //getAddChannelFlag(data.operator_id);
        })
        .catch((e) => {
          alert("error in getting details");
          console.log(e);
          isLoading = false;
        });
    }
  

    const show_type = localStorage.getItem("show_type");
    const can_pay = localStorage.getItem("can_pay");
    const exp_date = localStorage.getItem("PRE_END_DATE");

    if (show_type == "RENEW") {
      setStbFlagShow(true);
      setPaymentFlagShow(false);
    } else if (show_type == "PAYMENT") {
      setStbFlagShow(false);
      setPaymentFlagShow(true);
    } else if (show_type == "BLOCK") {
      if (can_pay == "N") {
        setStbFlagShow(false);
        setPaymentFlagShow(false);
        getRenewPaymentAlert();
      }
    }

    if (show_type == "RENEW") {
      if (exp_date == null || exp_date == "Invalid date" || exp_date == "") {
        setExpiryFlagShow(false);
      } else {
        setExpiryFlagShow(true);
      }
    } else {
      setExpiryFlagShow(false);
    }
  };

  const getRenewPaymentAlert = () => {
    const dateString = localStorage.getItem("PRE_END_DATE");

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const current1Date = new Date(currentDate);

    const a = parseInt(localStorage.getItem("rem_days"));
    const b = a - parseInt(5);
    const exDate = new Date();
    exDate.setDate(current1Date.getDate() + b);

    const day = exDate.getDate();
    const month = months[exDate.getMonth()];
    const year = exDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    let msg =
      "Your Set-Top-Box can be renewed on or after " +
      formattedDate +
      ". Please come back then.";

    if (localStorage.getItem("message") == "BOX NOT FOUND / DELETED") {
      Swal.fire({
        title:
          "We couldn't find an active box under your account. Please contact your " +
          localStorage.getItem("operatorName") +
          " cable operator",
        icon: "warning",
        customClass: {
          title: "swal-custom-title",
          actions: "swal-center-button",
        },
      });
    } else if (localStorage.getItem("message") == "LESS THAN 1") {
      Swal.fire({
        title: "Amount should be greater than (\u20B9) 1.00",
        icon: "warning",
        customClass: {
          title: "swal-custom-title",
          actions: "swal-center-button",
        },
      });
    } else if (localStorage.getItem("message") == "BEFORE EXPIERY") {
      Swal.fire({
        title: msg,
        // text: msg,
        icon: "warning",
        customClass: {
          title: "swal-custom-title",
          actions: "swal-center-button",
        },
      });
    } else if (
      localStorage.getItem("message") == "MORE THAN ONE BOX TO RENEW"
    ) {
      Swal.fire({
        title: "More Than One Set-Top-Box Not Allowed To Renew!",
        icon: "warning",
        customClass: {
          title: "swal-custom-title",
          actions: "swal-center-button",
        },
      });
    } else if (localStorage.getItem("message") == "FLAG MISS MATCH") {
      Swal.fire({
        title:
          "We're sorry, but payment and renewal services are currently unavailable. For help, please reach out to your " +
          localStorage.getItem("operatorName") +
          " cable operator using the contact information provided below.",
        icon: "warning",
        html: `
          <p class="swal-custom-title"><b>Contact Number:</b> <a href="tel:${localStorage.getItem(
            "op_phone"
          )}" class="phoneMail">${localStorage.getItem("op_phone")}</a></p>
          <p class="swal-custom-title"><b>Email:</b> <a href="mailto:${localStorage.getItem(
            "op_email"
          )}" class="phoneMail">${localStorage.getItem("op_email")}</a></p>
      `,
        customClass: {
          title: "swal-custom-title",
          actions: "swal-center-button",
        },
      });
    }
  };

  const fetchAdBanner = async () => {
    isLoading = true;
    let data = {
      OP_ID: localStorage.getItem("operatorId"),
    };

    await authApi2
      .fetchAds(data)
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          setAds(res.data.Banners);
          isLoading = false;
        }
      })
      .catch((e) => {
        console.log("Failed to fetch banner :", e);
        isLoading = false;
      });
  };

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const amount = localStorage.getItem("dueAmount");
  const stbno =
    localStorage.getItem("stbNumber") === null ||
    localStorage.getItem("stbNumber") === "none" ||
    localStorage.getItem("stbNumber") === ""
      ? "---"
      : localStorage.getItem("stbNumber");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [payDialogOpen, setPayDialogOpen] = useState(false);
  const [dueDialogOpen, setDueDialogOpen] = useState(false);
  const [channelDialogOpen, setChannelDialogOpen] = useState(false);
  const [ads, setAds] = useState([]);
  const history = useHistory();
  const [button1Loading, setButton1Loading] = useState(false);
  const [button2Loading, setButton2Loading] = useState(false);
  const [button3Loading, setButton3Loading] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [opDialogOpen, setOpDialogOpen] = useState(false);
  const [stbFlagShow, setStbFlagShow] = useState(false);
  const [paymentFlagShow, setPaymentFlagShow] = useState(false);
  const [expiryFlagShow, setExpiryFlagShow] = useState(false);

  const onSignIn = async () => {
    setButtonLoading(true);

    if (localStorage.getItem("can_pay") == "N") {
      setButtonLoading(false);
      getRenewPaymentAlert();
    } else {
      setPayDialogOpen(true);
    }
  };

  const newSign = async () => {
    let newDate = new Date();
    let getYear = newDate.getFullYear().toString();
    let getMonth = newDate.getMonth().toString();
    let getDate = newDate.getDate().toString();
    let getMSec = newDate.getMilliseconds().toString();
    let combineDate = getYear + getMonth + getDate + getMSec;

    let tranRef = "DISPT" + localStorage.getItem("custNum") + combineDate;

    // let tran =
    //   "TRNNXTSUBWEB" + parseInt(localStorage.getItem("ownerId")) + Date.now();
    let data = JSON.stringify({
      AMOUNT: localStorage.getItem("dueAmount"),
      PHONE: localStorage.getItem("phone"),
      OPERATOR_ID: localStorage.getItem("operatorId"),
      flag: "C",
      CUST_NUM: localStorage.getItem("custNum"),
      CUST_NAME: localStorage.getItem("custName"),
      tran_id: tranRef,
    });
    await AuthApi.cashfree_init(data)
      .then((response) => {
        console.log(response);
        if (response.data.p_out_mssg_flg === "S") {
          window.location.href =
            "https://apps.mobiezy.in/CASHFREE/INTEGRATED/cashfree_ui.php?paymentSessionId=" +
            response.data.payment_session_id;
        } else {
          setButtonLoading(false);
          swal("Error", response.data.p_out_mssg, "warning");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDialogOp = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handlePayDialogOp = () => {
    onSignIn();
  };

  const handleOpDialogOpen = () => {
    setOpDialogOpen(true);
  };

  const handleOpDialogClose = () => {
    setOpDialogOpen(false);
  };

  const handleDueDialogOp = async () => {
    setDueDialogOpen(true);
    const amount = localStorage.getItem("bill_total");
    console.log("Due Cleared", localStorage.getItem("custNum"));
    //  alert(localStorage.getItem("ownerId"));
    setButtonLoading(true);
    // isButtonLoading(true);

    if (localStorage.getItem("can_pay") == "N") {
      setButtonLoading(false);
      getRenewPaymentAlert();
    } else {
      setButtonLoading(true);
      // isButtonLoading=true;
      let newDate = new Date();
      let getYear = newDate.getFullYear().toString();
      let getMonth = newDate.getMonth().toString();
      let getDate = newDate.getDate().toString();
      let getMSec = newDate.getMilliseconds().toString();
      let combineDate = getYear + getMonth + getDate + getMSec;

      let tranRef = "DSPT" + localStorage.getItem("custNum") + combineDate;
      console.log(tranRef);
      // let tran =
      //   "TRNNXTSUBWEB" + parseInt(localStorage.getItem("ownerId")) + Date.now();
      let data = JSON.stringify({
        AMOUNT: localStorage.getItem("bill_total"),
        PHONE: localStorage.getItem("phone"),
        OPERATOR_ID: localStorage.getItem("operatorId"),
        flag: "A",
        CUST_NUM: localStorage.getItem("custNum"),
        customerName: localStorage.getItem("custName"),
        tran_id: tranRef,
      });
      console.log(data);
      await AuthApi.cashfree_init_payment(data)
        .then((response) => {
          console.log(response);
          if (response.data.p_out_mssg_flg === "S") {
            window.location.href =
              "https://apps.mobiezy.in/CASHFREE/QR_PAY/cashfree_ui.php?paymentSessionId=" +
              response.data.payment_session_id;
          } else {
            setButtonLoading(false);
            swal("Error", response.data.p_out_mssg, "warning");
          }
        })
        .catch((e) => {
          setButtonLoading(false);
          console.log(e);
        });
    }
  };

  const handlePayDialogClose = () => {
    // onSignIn();
    newSign();
    setPayDialogOpen(false);
  };

  const handleCancelDialogClose = () => {
    setPayDialogOpen(false);
    setButtonLoading(false);
  };

  const handleChannelDialogClose = () => {
    setChannelDialogOpen(false);
    setButton1Loading(false);
  };

  const redirect_url = (url) => {
    // window.location.href = url;
    console.log("Redirection URL:", url);

    if (url != null) {
      window.open(url);
    }
  };

  const dateString = localStorage.getItem("PRE_END_DATE");
  const dueAmount = parseFloat(localStorage.getItem("dueAmount"));

  const expDate = new Date(dateString);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = expDate.getDate();
  const month = months[expDate.getMonth()];
  const year = expDate.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;

  console.log(formattedDate);

  const current1Date = new Date(currentDate);

  const exDate = new Date();
  exDate.setDate(current1Date.getDate() + 15);

  // const new1Date = new Date(expDate);
  const new1Date = new Date(localStorage.getItem("PRE_END_DATE"));
  // console.log(current1Date,exDate,new1Date,dueAmount,localStorage.getItem("cust_due"));

  const a1 = parseInt(localStorage.getItem("rem_days"));
  const b1 = a1 - parseInt(5);
  const remDate = new Date();
  remDate.setDate(current1Date.getDate() + b1);

  const dayRem = remDate.getDate();
  const monthRem = months[remDate.getMonth()];
  const yearRem = remDate.getFullYear();

  const formattedRemDate = `${dayRem}-${monthRem}-${yearRem}`;

  let msg = "Your Set-Top-Box can be renewed on or after " + formattedRemDate;

  // console.log(current1Date,exDate,new1Date,dueAmount,stb_flag,payment_flag);

  // --------------------------------Old Fetch Add Function---------------------------------------------
  // const renderAdsBanners = (ads) => {
  //   return ads.map((number, index) =>
  //       <div style={{backgroundColor: "#151719",cursor: "pointer"}} >
  //         {/* <img key={index} src={number.LINK} alt="ADS Banner" onClick={() => redirect_url(number.REDIRECT !== null? number.REDIRECT:"")} /> */}
  //         <img key={index} src={number.LINK} alt="ADS Banner" onClick={() => redirect_url(number.REDIRECT)} />
  //       </div>
  //   );
  // }

  const renderAdsBanners = (ads) => {
    return ads.map((number, index) => (
      <div style={{ backgroundColor: "#151719", cursor: "pointer" }}>
        <img
          key={index}
          src={number.LINK}
          alt="ADS Banner"
          onClick={() => redirect_url(number.REDIRECT)}
        />
      </div>
    ));
  };

  return isLoading === true ? (
    <center>
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className="hero-content">
            <div style={{ marginTop: "120px" }}>
              <Loader
                type="box-rectangular"
                bgColor={"#FFA500"}
                color={"#FFA500"}
                size={80}
              />
            </div>
          </div>
        </div>
      </section>
    </center>
  ) : (
    <>
      <Header title={"Hero"} onContactBtnClick={handleOpDialogOpen} />
      <center>
        <section {...props} className={outerClasses}>
          <div className="container-sm">
            <hr></hr>
            <div className="container-xs" style={{ marginTop: "40px" }}>
              <AutoplaySlider
                // renderPagination={() => null}
                // dotStyle={{
                //   display: "none",
                // }}
                play={true}
                cancelOnInteraction={true} // should stop playing on user interaction
                interval={5000}
              >
                {/* {renderAdsBanners(ads)} */}
                {ads && ads.length > 0 ? (
                  renderAdsBanners(ads)
                ) : (
                  <div
                    style={{ backgroundColor: "#151719", cursor: "pointer" }}
                  >
                    <img
                      src="https://banners-cableguy.s3.us-west-2.amazonaws.com/payment.png"
                      alt="ADS Banner"
                      onClick={() => redirect_url(null)}
                    />
                  </div>
                )}
              </AutoplaySlider>{" "}
            </div>
            {/**/}
            {/* <div data-src={require("../../assets/images/covid.png")} /> */}
            {/* <div data-src={require("../../assets/images/payment.png")} /> */}
            {/* <div data-src="https://i.picsum.photos/id/46/200/300.jpg?hmac=hO6W_-hkJCRf3aWSzs0SkaWPFlMhZsixxrObPW_sFaY" /> */}
            <hr></hr>
            <div style={{ textAlign: "center", fontWeight: "700" }}>
              <h3 style={{ color: "#1CB68B" }}>
                {localStorage.getItem("operatorName")}
              </h3>
            </div>

            {localStorage.getItem("message") === "BEFORE EXPIERY" ? (
              <div className="MoveText">
                <div className="moving-text">
                  <h5 style={{ color: "orange" }}>{msg}</h5>
                </div>
              </div>
            ) : null}

            <div
              className="hero-content"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: "15px",
                  // border: '2px solid orange',
                  // borderRadius: '15px',
                  // padding: '20px'
                }}
              >
                <Typography variant={isSmallScreen ? "button" : "h6"}>
                  <FontAwesomeIcon icon={faCircleUser} color="orange" />
                  &nbsp;{" "}
                  <span className="text-color-primary">
                    {" "}
                    Name
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                  </span>
                  <span className="text-color-light">
                    {localStorage.getItem("custName")}
                  </span>
                </Typography>

                <Typography variant={isSmallScreen ? "button" : "h6"}>
                  <FontAwesomeIcon icon={faDisplay} color="orange" />{" "}
                  {localStorage.getItem("MSO_ID") === "14" ? (
                    <>
                      <span className="text-color-primary">
                        VC Number
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                      </span>
                      <span className="text-color-light">
                        {localStorage.getItem("VC_NUMBER")}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-color-primary">
                        STB Number &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                      </span>
                      <span className="text-color-light">{stbno}</span>
                    </>
                  )}
                </Typography>
                {expiryFlagShow ? (
                  <Typography variant={isSmallScreen ? "button" : "h6"}>
                    <FontAwesomeIcon icon={faCalendarDay} color="orange" />
                    &nbsp;{" "}
                    <span className="text-color-primary">
                      {" "}
                      {"  "}Expiry Date
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                    </span>
                    <span className="text-color-light">
                      {localStorage.getItem("PRE_END_DATE")}
                    </span>
                  </Typography>
                ) : (
                  ""
                )}

                {paymentFlagShow ? (
                  <Typography variant={isSmallScreen ? "button" : "h6"}>
                    <FontAwesomeIcon icon={faReceipt} color="orange" />
                    &nbsp;
                    <span className="text-color-primary">
                      {" "}
                      Payable Amount :{" "}
                    </span>
                    <span className="text-color-light">
                      {"\u20B9"} {localStorage.getItem("bill_total")}
                    </span>
                    {/* <FontAwesomeIcon style={{marginLeft: '10px', width: '15px', height: '15px'}} icon={faCircleInfo} color="aqua" onClick={() => handleDialogOp()}/> */}
                  </Typography>
                ) : null}
                {stbFlagShow ? (
                  <Typography variant={isSmallScreen ? "button" : "h6"}>
                    <FontAwesomeIcon icon={faReceipt} color="orange" />
                    &nbsp;
                    <span className="text-color-primary">
                      {" "}
                      Payable Amount :{" "}
                    </span>
                    <span className="text-color-light">
                      {"\u20B9"} {localStorage.getItem("dueAmount")}
                    </span>
                    {localStorage.getItem("MSO_ID") === "12" ? (
                      ""
                    ) : (
                      <FontAwesomeIcon
                        style={{
                          marginLeft: "10px",
                          width: "15px",
                          height: "15px",
                        }}
                        icon={faCircleInfo}
                        color="aqua"
                        onClick={() => handleDialogOp()}
                      />
                    )}
                  </Typography>
                ) : null}
                {paymentFlagShow ? (
                  isButtonLoading === false ? (
                    <div className="container-sm">
                      <Button
                        style={{
                          width: isSmallScreen ? "80%" : "60%",
                          border: "2px solid orange",
                          borderRadius: "15px",
                          marginTop: "15px",
                        }}
                        tag="a"
                        color="primary"
                        wideMobile
                        onClick={() => handleDueDialogOp()}
                      >
                        Proceed to Pay
                      </Button>
                    </div>
                  ) : (
                    <center style={{ width: "100%" }}>
                      <div className="container-sm">
                        <div className="hero-content">
                          <div style={{ marginTop: "5px" }}>
                            <Loader
                              type="box-rectangular"
                              bgColor={"#FFA500"}
                              color={"#FFA500"}
                              size={isSmallScreen ? 60 : 80}
                            />
                          </div>
                        </div>
                      </div>
                    </center>
                  )
                ) : null}
                {stbFlagShow ? (
                  isButtonLoading === false ? (
                    <div className="container-sm">
                      <Button
                        style={{
                          width: isSmallScreen ? "80%" : "60%",
                          border: "2px solid orange",
                          borderRadius: "15px",
                          marginTop: "15px",
                        }}
                        tag="a"
                        color="primary"
                        wideMobile
                        onClick={() => handlePayDialogOp()}
                      >
                        Renew
                      </Button>
                    </div>
                  ) : (
                    <center style={{ width: "100%" }}>
                      <div className="container-sm">
                        <div className="hero-content">
                          <div style={{ marginTop: "5px" }}>
                            <Loader
                              type="box-rectangular"
                              bgColor={"#FFA500"}
                              color={"#FFA500"}
                              size={isSmallScreen ? 60 : 80}
                            />
                          </div>
                        </div>
                      </div>
                    </center>
                  )
                ) : null}

                {/* <div style={{width: "100%",textAlign: "center"}}><h5 style={{margin: "0px"}}>Other Operations</h5></div> */}
                {localStorage.getItem("AddChannelFlag") &&
                localStorage.getItem("AddChannelFlag") === "Y" ? (
                  button1Loading === false ? (
                    <div className="container-sm">
                      <Button
                        style={{
                          width: isSmallScreen ? "80%" : "60%",
                          border: "2px solid orange",
                          borderRadius: "15px",
                          marginTop: "15px",
                        }}
                        tag="a"
                        color="primary"
                        wideMobile
                        onClick={() => {
                          history.push("/AddChannel");
                          //checkAddChannelFlag();
                        }}
                      >
                        Add Channel
                      </Button>
                    </div>
                  ) : (
                    <center style={{ width: "100%" }}>
                      <div className="container-sm">
                        <div className="hero-content">
                          <div style={{ marginTop: "5px" }}>
                            <Loader
                              type="box-rectangular"
                              bgColor={"#FFA500"}
                              color={"#FFA500"}
                              size={isSmallScreen ? 60 : 80}
                            />
                          </div>
                        </div>
                      </div>
                    </center>
                  )
                ) : (
                  ""
                )}
                {button2Loading === false ? (
                  <div className="container-sm">
                    <Button
                      style={{
                        width: isSmallScreen ? "80%" : "60%",
                        border: "2px solid orange",
                        borderRadius: "15px",
                        marginTop: "15px",
                      }}
                      tag="a"
                      color="primary"
                      wideMobile
                      onClick={() => {
                        history.push("/AddComplaints");
                        // setButton2Loading(true);
                      }}
                    >
                      Complaints
                    </Button>
                  </div>
                ) : (
                  <center style={{ width: "100%" }}>
                    <div className="container-sm">
                      <div className="hero-content">
                        <div style={{ marginTop: "5px" }}>
                          <Loader
                            type="box-rectangular"
                            bgColor={"#FFA500"}
                            color={"#FFA500"}
                            size={isSmallScreen ? 60 : 80}
                          />
                        </div>
                      </div>
                    </div>
                  </center>
                )}
                {button3Loading === false ? (
                  <div className="container-sm">
                    <Button
                      style={{
                        width: isSmallScreen ? "80%" : "60%",
                        border: "2px solid orange",
                        borderRadius: "15px",
                        marginTop: "15px",
                      }}
                      tag="a"
                      color="primary"
                      wideMobile
                      onClick={() => {
                        history.push("/Transactions");
                      }}
                    >
                      Transactions
                    </Button>
                  </div>
                ) : (
                  <center style={{ width: "100%" }}>
                    <div className="container-sm">
                      <div className="hero-content">
                        <div style={{ marginTop: "5px" }}>
                          <Loader
                            type="box-rectangular"
                            bgColor={"#FFA500"}
                            color={"#FFA500"}
                            size={isSmallScreen ? 60 : 80}
                          />
                        </div>
                      </div>
                    </div>
                  </center>
                )}
              </div>
            </div>
            <p></p>
          </div>
          {/*---------------- The following dialog is to show amount break-up ----------------*/}
          <Dialog open={dialogOpen}>
            <DialogTitle>
              <Typography color={"black"}>Amount Break-Up</Typography>
            </DialogTitle>
            <DialogContent>
              <Typography>
                <table>
                  <tr>
                    <td>Pack Price</td>
                    <td>:</td>
                    <td>₹{localStorage.getItem("packTotal")}</td>
                  </tr>
                  <tr>
                    <td>Due</td>
                    <td>:</td>
                    <td>₹{localStorage.getItem("cust_due")}</td>
                  </tr>
                  <tr>
                    <td>Adjustments</td>
                    <td>:</td>
                    <td>₹{localStorage.getItem("advn_dep_amnt")}</td>
                  </tr>
                  <tr>
                    <td>Total Payable</td>
                    <td>:</td>
                    <td>₹{localStorage.getItem("dueAmount")}</td>
                  </tr>
                </table>
              </Typography>
              <DialogActions>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#1CB68B",
                    color: "white",
                    border: "0px solid green",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    handleDialogClose();
                  }}
                  onClose={() => {
                    handleDialogClose();
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          {/*---------------- The following dialog is to tell customer to turn-on their STB ----------------*/}
          <Dialog open={payDialogOpen}>
            <DialogTitle>
              <Typography color={"black"}>Attention!!</Typography>
            </DialogTitle>
            <DialogContent>
              <Typography>
                Please make sure your Set-Top-Box is turned on before payment.
              </Typography>
              <DialogActions>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    color: "#1CB68B",
                    border: "2px solid #1CB68B",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    handleCancelDialogClose();
                  }}
                  onClose={() => {
                    handleCancelDialogClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#1CB68B",
                    color: "white",
                    border: "0px solid green",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    handlePayDialogClose();
                  }}
                  onClose={() => {
                    handlePayDialogClose();
                  }}
                >
                  Proceed
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          {/*---------------- The following dialog is to inform user that this particular feature is not available for them. ----------------*/}
          <Dialog open={channelDialogOpen}>
            <DialogTitle>
              <Typography color={"black"}>Oops! Feature Unavailable</Typography>
            </DialogTitle>
            <DialogContent>
              <Typography>
                This feature is not yet available for you.
              </Typography>
              <DialogActions>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#1CB68B",
                    color: "white",
                    border: "0px solid green",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    handleChannelDialogClose();
                  }}
                  onClose={() => {
                    handleChannelDialogClose();
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Dialog open={opDialogOpen}>
            <DialogTitle>
              <Typography variant="h6" color={"black"} align="center">
                Contact Info
              </Typography>
            </DialogTitle>
            <DialogContent align="left">
              <DialogContentText>
                Network Name:&nbsp;
                <span style={{ color: "#9CA9B3" }}>
                  {localStorage.getItem("operatorName")}
                </span>
              </DialogContentText>
              <DialogContentText>
                Phone&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                {localStorage.getItem("op_phone") ? (
                  <a
                    href={`tel:${localStorage.getItem("op_phone")}`}
                    style={{ color: "#9CA9B3" }}
                  >
                    {localStorage.getItem("op_phone")}
                  </a>
                ) : (
                  ""
                )}
              </DialogContentText>
              <DialogContentText>
                Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
                {localStorage.getItem("op_email") ? (
                  <a
                    href={`mailto:${localStorage.getItem("op_email")}`}
                    style={{ color: "#9CA9B3" }}
                  >
                    {localStorage.getItem("op_email")}
                  </a>
                ) : (
                  ""
                )}
              </DialogContentText>
              <DialogContentText>
                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    width: "100%",
                  }}
                >
                  <span style={{ marginRight: "5px", whiteSpace: "nowrap" }}>
                    Address&nbsp;:
                  </span>
                  <div style={{ flexGrow: 1, color: "#9CA9B3" }}>
                    {localStorage.getItem("op_address")}
                  </div>
                </div>
              </DialogContentText>
              <DialogActions>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#1CB68B",
                    color: "white",
                    border: "0px solid green",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    handleOpDialogClose();
                  }}
                  onClose={() => {
                    handleOpDialogClose();
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </section>
      </center>
    </>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
